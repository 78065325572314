// extracted by mini-css-extract-plugin
export var root = "PlasmicBanner-module--root--1A_Xz";
export var right = "PlasmicBanner-module--right--3yG_5";
export var img___0A0Uj = "PlasmicBanner-module--img___0A0Uj--2M-lF";
export var box = "PlasmicBanner-module--box--3G_Ii";
export var left = "PlasmicBanner-module--left--1jwCC";
export var box__ip0Rw = "PlasmicBanner-module--box__ip0Rw--1TRS9";
export var box__gxT = "PlasmicBanner-module--box__gxT--1pKza";
export var svg__wC9M1 = "PlasmicBanner-module--svg__wC9M1--1AgZW";
export var box___0QjE = "PlasmicBanner-module--box___0QjE--36ltI";
export var svg___8NrD = "PlasmicBanner-module--svg___8NrD--oXcCu";