// extracted by mini-css-extract-plugin
export var root = "PlasmicNavigation-module--root--bfxeZ";
export var root__lightBg = "PlasmicNavigation-module--root__lightBg--jr-ep";
export var box__sEIs8 = "PlasmicNavigation-module--box__sEIs8--2Od3X";
export var logo__l1UL5 = "PlasmicNavigation-module--logo__l1UL5--3nJL6";
export var box__cwQy = "PlasmicNavigation-module--box__cwQy--2XzQg";
export var button__ou4Ka = "PlasmicNavigation-module--button__ou4Ka--RoLyV";
export var svg__bzPru = "PlasmicNavigation-module--svg__bzPru--2G66z";
export var svg__vi8Fl = "PlasmicNavigation-module--svg__vi8Fl--3SVXT";
export var button__aXflz = "PlasmicNavigation-module--button__aXflz--J2zgi";
export var svg__msX1F = "PlasmicNavigation-module--svg__msX1F--3Fnr3";
export var svg__kpfwB = "PlasmicNavigation-module--svg__kpfwB--mdsqT";
export var button___6Yiqa = "PlasmicNavigation-module--button___6Yiqa--3g8vF";
export var svg___5KQY = "PlasmicNavigation-module--svg___5KQY--20vSR";
export var svg__oe2UR = "PlasmicNavigation-module--svg__oe2UR--2ZN0t";
export var button__jwc1S = "PlasmicNavigation-module--button__jwc1S--2ojZZ";
export var svg__dcpf5 = "PlasmicNavigation-module--svg__dcpf5--2fsFp";
export var svg__fWcfk = "PlasmicNavigation-module--svg__fWcfk--3OM3q";
export var button__vqhLh = "PlasmicNavigation-module--button__vqhLh--3fk0j";
export var svg__rwCFs = "PlasmicNavigation-module--svg__rwCFs--1mwO8";
export var svg__iUClh = "PlasmicNavigation-module--svg__iUClh--2xaUD";
export var button__esvHh = "PlasmicNavigation-module--button__esvHh--2h3vK";
export var svg__kvVra = "PlasmicNavigation-module--svg__kvVra--Yyvxc";
export var svg__rzEmi = "PlasmicNavigation-module--svg__rzEmi--2kEfA";
export var box__rkBlO = "PlasmicNavigation-module--box__rkBlO--2dKtm";
export var button__jxli8 = "PlasmicNavigation-module--button__jxli8--3CQtK";
export var button__dark__jxli8MD3G1 = "PlasmicNavigation-module--button__dark__jxli8MD3G1--1Pli-";
export var button__y24UP = "PlasmicNavigation-module--button__y24UP--3JuZp";
export var svg__f8VmO = "PlasmicNavigation-module--svg__f8VmO--32cd9";
export var svg__uDzDl = "PlasmicNavigation-module--svg__uDzDl--1Vqo9";