// extracted by mini-css-extract-plugin
export var root = "PlasmicValueprop-module--root--3WQij";
export var root__vertical = "PlasmicValueprop-module--root__vertical--OZ9Gp";
export var root__card = "PlasmicValueprop-module--root__card--2mWIi";
export var box__dTlup = "PlasmicValueprop-module--box__dTlup--1noBV";
export var box__flat__dTlupf3B3M = "PlasmicValueprop-module--box__flat__dTlupf3B3M--3Yng0";
export var box__vertical__dTlupUoa7M = "PlasmicValueprop-module--box__vertical__dTlupUoa7M--Npy-U";
export var box__card__dTlupzublL = "PlasmicValueprop-module--box__card__dTlupzublL--1dKLH";
export var box__flatIcon__dTlupHWlc = "PlasmicValueprop-module--box__flatIcon__dTlupHWlc--3vPRq";
export var img__dToGw = "PlasmicValueprop-module--img__dToGw--2L3gI";
export var box__bdgEf = "PlasmicValueprop-module--box__bdgEf--3RW5g";
export var box__flat__bdgEff3B3M = "PlasmicValueprop-module--box__flat__bdgEff3B3M--2uvoE";
export var box__vertical__bdgEfUoa7M = "PlasmicValueprop-module--box__vertical__bdgEfUoa7M--2gEh9";
export var box__card__bdgEfzublL = "PlasmicValueprop-module--box__card__bdgEfzublL--3oMh_";
export var box__flatIcon__bdgEfHWlc = "PlasmicValueprop-module--box__flatIcon__bdgEfHWlc--1l0Uw";
export var svg___400Ba = "PlasmicValueprop-module--svg___400Ba--1XKcD";
export var box__k0My9 = "PlasmicValueprop-module--box__k0My9--2V3rn";
export var slotChildren = "PlasmicValueprop-module--slotChildren--20Pu_";
export var slotChildren__dark = "PlasmicValueprop-module--slotChildren__dark--1LUqw";
export var slotSlot = "PlasmicValueprop-module--slotSlot--iNgp1";
export var slotSlot__dark = "PlasmicValueprop-module--slotSlot__dark--gIYkb";