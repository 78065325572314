// extracted by mini-css-extract-plugin
export var root = "PlasmicTestimonialsSection1-module--root--1ALi-";
export var box = "PlasmicTestimonialsSection1-module--box--3gnwK";
export var testimonial__hHov = "PlasmicTestimonialsSection1-module--testimonial__hHov--2K4bV";
export var img__rxFiv = "PlasmicTestimonialsSection1-module--img__rxFiv--heJXj";
export var testimonial__vapv7 = "PlasmicTestimonialsSection1-module--testimonial__vapv7--2A92K";
export var img__vY2Tq = "PlasmicTestimonialsSection1-module--img__vY2Tq--tkJnc";
export var testimonial__jLbUu = "PlasmicTestimonialsSection1-module--testimonial__jLbUu--2qbfn";
export var box__iPaoH = "PlasmicTestimonialsSection1-module--box__iPaoH--1d9ag";
export var box__vEle1 = "PlasmicTestimonialsSection1-module--box__vEle1--2_tnA";
export var img__seAex = "PlasmicTestimonialsSection1-module--img__seAex--26838";
export var testimonial__bb1QO = "PlasmicTestimonialsSection1-module--testimonial__bb1QO--3si2R";
export var box__pSynS = "PlasmicTestimonialsSection1-module--box__pSynS--2rWtT";
export var img__bgYfn = "PlasmicTestimonialsSection1-module--img__bgYfn--1d94R";