// extracted by mini-css-extract-plugin
export var root = "PlasmicValuesSection2Dark-module--root--1W-i1";
export var foreground = "PlasmicValuesSection2Dark-module--foreground--U1hWo";
export var box__ziZj6 = "PlasmicValuesSection2Dark-module--box__ziZj6--3_qFp";
export var box__oeM0K = "PlasmicValuesSection2Dark-module--box__oeM0K--3w8hg";
export var h2__z2BrT = "PlasmicValuesSection2Dark-module--h2__z2BrT--2S0ZI";
export var box__ogiPz = "PlasmicValuesSection2Dark-module--box__ogiPz--2QoWI";
export var button__nhmms = "PlasmicValuesSection2Dark-module--button__nhmms--28sMf";
export var svg__yVdZl = "PlasmicValuesSection2Dark-module--svg__yVdZl--Stwhq";
export var box___4Vfen = "PlasmicValuesSection2Dark-module--box___4Vfen--2dHJG";
export var svg__hqtiP = "PlasmicValuesSection2Dark-module--svg__hqtiP--2E-3D";
export var box__yb1Bn = "PlasmicValuesSection2Dark-module--box__yb1Bn--2VT2O";
export var listItem___20JJh = "PlasmicValuesSection2Dark-module--listItem___20JJh--1gFKS";
export var box___7Dq1C = "PlasmicValuesSection2Dark-module--box___7Dq1C--2luyo";
export var svg__ftaRr = "PlasmicValuesSection2Dark-module--svg__ftaRr--3FSYb";
export var listItem__oNaWa = "PlasmicValuesSection2Dark-module--listItem__oNaWa--284Fi";
export var box__ioRmm = "PlasmicValuesSection2Dark-module--box__ioRmm--kem8V";
export var svg__o6N8 = "PlasmicValuesSection2Dark-module--svg__o6N8--1hIa7";
export var listItem___0PR6 = "PlasmicValuesSection2Dark-module--listItem___0PR6--1XdXf";
export var box__v7Bo0 = "PlasmicValuesSection2Dark-module--box__v7Bo0--309du";
export var svg__uwWlc = "PlasmicValuesSection2Dark-module--svg__uwWlc--1HPEN";
export var listItem__m4XjU = "PlasmicValuesSection2Dark-module--listItem__m4XjU--39hIW";
export var box__symdL = "PlasmicValuesSection2Dark-module--box__symdL--2Gltn";
export var svg__xz2Qf = "PlasmicValuesSection2Dark-module--svg__xz2Qf--hPY47";
export var listItem__eTpHc = "PlasmicValuesSection2Dark-module--listItem__eTpHc--3ciC0";
export var box___1GR = "PlasmicValuesSection2Dark-module--box___1GR--3JFZi";
export var svg__feQmA = "PlasmicValuesSection2Dark-module--svg__feQmA--38Jfg";