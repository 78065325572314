// extracted by mini-css-extract-plugin
export var root = "PlasmicShopBanners-module--root--1a0Ay";
export var banner__w3Krt = "PlasmicShopBanners-module--banner__w3Krt--1Jhl4";
export var img__d05Pq = "PlasmicShopBanners-module--img__d05Pq--1XmEO";
export var box__hTOs = "PlasmicShopBanners-module--box__hTOs--1PBqA";
export var box__mkVp8 = "PlasmicShopBanners-module--box__mkVp8--3hcdf";
export var svg___3DvVj = "PlasmicShopBanners-module--svg___3DvVj--1oyml";
export var box__qzXfc = "PlasmicShopBanners-module--box__qzXfc--1Ehl1";
export var svg___4HNF = "PlasmicShopBanners-module--svg___4HNF--vV2Qd";
export var box__rAaK1 = "PlasmicShopBanners-module--box__rAaK1--19-Rv";
export var img__atwJi = "PlasmicShopBanners-module--img__atwJi--1TyjE";
export var box__md0Xa = "PlasmicShopBanners-module--box__md0Xa--1irYw";
export var box__bFrh2 = "PlasmicShopBanners-module--box__bFrh2--24wI2";
export var svg___86Mor = "PlasmicShopBanners-module--svg___86Mor--3HLnT";
export var box__uRPet = "PlasmicShopBanners-module--box__uRPet--VXAON";
export var svg__u2Xco = "PlasmicShopBanners-module--svg__u2Xco--1GN8N";
export var banner__mtAh9 = "PlasmicShopBanners-module--banner__mtAh9--Rf75l";
export var img__jpS8E = "PlasmicShopBanners-module--img__jpS8E--2e9tz";
export var box__xmOwR = "PlasmicShopBanners-module--box__xmOwR--mjk2i";
export var box__azx0W = "PlasmicShopBanners-module--box__azx0W--TIJlZ";
export var svg__zd6Wo = "PlasmicShopBanners-module--svg__zd6Wo--1ZMWE";
export var box__fOw0M = "PlasmicShopBanners-module--box__fOw0M--1zzTe";
export var svg___7HjE = "PlasmicShopBanners-module--svg___7HjE--2CeOF";