// extracted by mini-css-extract-plugin
export var root = "PlasmicPricingSection3-module--root--1BdWg";
export var box = "PlasmicPricingSection3-module--box--3aOqk";
export var foreground = "PlasmicPricingSection3-module--foreground--1Fnlx";
export var box__vHLoB = "PlasmicPricingSection3-module--box__vHLoB--2ue9b";
export var box__vVgR = "PlasmicPricingSection3-module--box__vVgR--1JX5-";
export var box__ekVa3 = "PlasmicPricingSection3-module--box__ekVa3--1-jji";
export var box__dLdFo = "PlasmicPricingSection3-module--box__dLdFo--1wpLH";
export var box__hOiEx = "PlasmicPricingSection3-module--box__hOiEx--nNTXg";
export var box__rApY0 = "PlasmicPricingSection3-module--box__rApY0--1bFf4";
export var box__ldgyw = "PlasmicPricingSection3-module--box__ldgyw--wxdr7";
export var box__lo8Yq = "PlasmicPricingSection3-module--box__lo8Yq--Mf6Cv";
export var box__k1ZcU = "PlasmicPricingSection3-module--box__k1ZcU--2k8tq";
export var box__xhEzv = "PlasmicPricingSection3-module--box__xhEzv--1zhaL";
export var box__ppS7E = "PlasmicPricingSection3-module--box__ppS7E--AekbS";
export var box__mS2J = "PlasmicPricingSection3-module--box__mS2J--CY_ho";
export var box__yfxz5 = "PlasmicPricingSection3-module--box__yfxz5--3BQbb";
export var divider__gd1WG = "PlasmicPricingSection3-module--divider__gd1WG--3UXjf";
export var box__xdaRj = "PlasmicPricingSection3-module--box__xdaRj--1kxpu";
export var box__oeJ2 = "PlasmicPricingSection3-module--box__oeJ2--Dm_Wn";
export var valueprop__bWwxB = "PlasmicPricingSection3-module--valueprop__bWwxB--4k4-e";
export var svg___1CaWk = "PlasmicPricingSection3-module--svg___1CaWk--2eiB1";
export var svg__nh9Mp = "PlasmicPricingSection3-module--svg__nh9Mp--IakV9";
export var valueprop__t01Da = "PlasmicPricingSection3-module--valueprop__t01Da--1ZwMR";
export var svg__aJqT5 = "PlasmicPricingSection3-module--svg__aJqT5--G4Dkf";
export var svg__rGlz = "PlasmicPricingSection3-module--svg__rGlz--GG_J1";
export var svg__b2SZ = "PlasmicPricingSection3-module--svg__b2SZ--2a8Bi";
export var box__b9P6Y = "PlasmicPricingSection3-module--box__b9P6Y--3AMjZ";
export var svg__xAmtI = "PlasmicPricingSection3-module--svg__xAmtI--2WOBo";
export var box__wbjCv = "PlasmicPricingSection3-module--box__wbjCv--2mzdL";
export var box__yhIy = "PlasmicPricingSection3-module--box__yhIy--sI5Gg";
export var box__mVckX = "PlasmicPricingSection3-module--box__mVckX--2YdfZ";
export var box__cb0Yt = "PlasmicPricingSection3-module--box__cb0Yt--2ZZda";
export var box__wpuMn = "PlasmicPricingSection3-module--box__wpuMn--7uN5Y";
export var box___5Wgs8 = "PlasmicPricingSection3-module--box___5Wgs8--3aHmH";
export var box__wxBc = "PlasmicPricingSection3-module--box__wxBc--1KBpT";
export var box__lchnq = "PlasmicPricingSection3-module--box__lchnq--2_-Tv";
export var divider__pbdMd = "PlasmicPricingSection3-module--divider__pbdMd--2fQF_";
export var box__ax3Zf = "PlasmicPricingSection3-module--box__ax3Zf--qgDOu";
export var box__yhyjJ = "PlasmicPricingSection3-module--box__yhyjJ--42FmV";
export var valueprop__rQ20Z = "PlasmicPricingSection3-module--valueprop__rQ20Z--3yIcf";
export var svg__qhhuk = "PlasmicPricingSection3-module--svg__qhhuk--1Ei5E";
export var svg__rR4Qp = "PlasmicPricingSection3-module--svg__rR4Qp--3jYTM";
export var valueprop__v2Ci6 = "PlasmicPricingSection3-module--valueprop__v2Ci6--26Kca";
export var box__jhwyP = "PlasmicPricingSection3-module--box__jhwyP--OD5Ok";
export var svg__lDczA = "PlasmicPricingSection3-module--svg__lDczA--2YaDa";
export var svg__r5W6X = "PlasmicPricingSection3-module--svg__r5W6X--3KO1V";
export var valueprop__rn45B = "PlasmicPricingSection3-module--valueprop__rn45B--2zHWz";
export var svg__rifn = "PlasmicPricingSection3-module--svg__rifn--1aH4I";
export var svg__q80Bb = "PlasmicPricingSection3-module--svg__q80Bb--2jszc";
export var valueprop__bs7H4 = "PlasmicPricingSection3-module--valueprop__bs7H4--3Uuut";
export var box__q5I8K = "PlasmicPricingSection3-module--box__q5I8K--1yZCn";
export var svg__ig2AV = "PlasmicPricingSection3-module--svg__ig2AV--1bZaM";
export var svg__vmMCu = "PlasmicPricingSection3-module--svg__vmMCu--2AglT";
export var svg__yq29B = "PlasmicPricingSection3-module--svg__yq29B--3iz6o";
export var box__n0Dn7 = "PlasmicPricingSection3-module--box__n0Dn7--3bhrh";
export var svg__mdPku = "PlasmicPricingSection3-module--svg__mdPku--18lN-";
export var box__vbUh8 = "PlasmicPricingSection3-module--box__vbUh8--1ETHL";
export var box__r5HkR = "PlasmicPricingSection3-module--box__r5HkR--3S8vv";
export var box__zqees = "PlasmicPricingSection3-module--box__zqees--2nRs9";
export var box___8Bd36 = "PlasmicPricingSection3-module--box___8Bd36--Dag-f";
export var box__wC94M = "PlasmicPricingSection3-module--box__wC94M--2qlwC";
export var box___54BGv = "PlasmicPricingSection3-module--box___54BGv--1csQW";
export var box___2RvB2 = "PlasmicPricingSection3-module--box___2RvB2--2jriu";
export var box__caLDq = "PlasmicPricingSection3-module--box__caLDq--2Mnlv";
export var divider___5EfEj = "PlasmicPricingSection3-module--divider___5EfEj--1lJ90";
export var box__wam9M = "PlasmicPricingSection3-module--box__wam9M--kuF1h";
export var box__tTy = "PlasmicPricingSection3-module--box__tTy--18Ync";
export var valueprop__dvjf2 = "PlasmicPricingSection3-module--valueprop__dvjf2--3MlA8";
export var svg__cmf0V = "PlasmicPricingSection3-module--svg__cmf0V--O6zz3";
export var svg__jyWsf = "PlasmicPricingSection3-module--svg__jyWsf--3OeyM";
export var valueprop__n37L = "PlasmicPricingSection3-module--valueprop__n37L--2jOO9";
export var svg___8C6Zo = "PlasmicPricingSection3-module--svg___8C6Zo--3DP_5";
export var svg__xoy4R = "PlasmicPricingSection3-module--svg__xoy4R--35ac5";
export var svg__oVaTz = "PlasmicPricingSection3-module--svg__oVaTz--2InuT";
export var box___86LPp = "PlasmicPricingSection3-module--box___86LPp--2Th46";
export var svg__juG93 = "PlasmicPricingSection3-module--svg__juG93--28AzG";