// extracted by mini-css-extract-plugin
export var root = "PlasmicValuesSection3-module--root--1HkC5";
export var foreground = "PlasmicValuesSection3-module--foreground--2yaCl";
export var box__mdNmj = "PlasmicValuesSection3-module--box__mdNmj--sMJ3_";
export var box__qk3Db = "PlasmicValuesSection3-module--box__qk3Db--8OHai";
export var h2__wn1NB = "PlasmicValuesSection3-module--h2__wn1NB--mC0xh";
export var box__ryW8 = "PlasmicValuesSection3-module--box__ryW8--Xw3Du";
export var box__kf8E8 = "PlasmicValuesSection3-module--box__kf8E8--SM_kw";
export var box__eLcUq = "PlasmicValuesSection3-module--box__eLcUq--2ummz";
export var valueprop__qoEhS = "PlasmicValuesSection3-module--valueprop__qoEhS--3tdJy";
export var svg___7L5Q = "PlasmicValuesSection3-module--svg___7L5Q--2fSPl";
export var svg___9Ud1M = "PlasmicValuesSection3-module--svg___9Ud1M--1Y5hJ";
export var valueprop__rdPug = "PlasmicValuesSection3-module--valueprop__rdPug--1EXiA";
export var svg__wjr6O = "PlasmicValuesSection3-module--svg__wjr6O--AS_vl";
export var svg__mftJo = "PlasmicValuesSection3-module--svg__mftJo--3WqyK";
export var valueprop__uXve = "PlasmicValuesSection3-module--valueprop__uXve--3v4QB";
export var svg__cyRi = "PlasmicValuesSection3-module--svg__cyRi--3mveq";
export var svg___7Lq0G = "PlasmicValuesSection3-module--svg___7Lq0G--2XiUu";
export var valueprop__tPx2S = "PlasmicValuesSection3-module--valueprop__tPx2S--a_fmP";
export var svg__k3Mzw = "PlasmicValuesSection3-module--svg__k3Mzw--2DxmP";
export var svg__kqumo = "PlasmicValuesSection3-module--svg__kqumo--fPt1V";
export var box__rBxGu = "PlasmicValuesSection3-module--box__rBxGu--fQgH2";
export var box__zkimD = "PlasmicValuesSection3-module--box__zkimD--2EIqE";
export var box__ng2JP = "PlasmicValuesSection3-module--box__ng2JP--UxARK";
export var valueprop__gTkZd = "PlasmicValuesSection3-module--valueprop__gTkZd--195f4";
export var svg__vkwsJ = "PlasmicValuesSection3-module--svg__vkwsJ--Zue1x";
export var svg___7Cie = "PlasmicValuesSection3-module--svg___7Cie--2sA74";
export var box__kvBbA = "PlasmicValuesSection3-module--box__kvBbA--1Rx8J";
export var valueprop__lARoJ = "PlasmicValuesSection3-module--valueprop__lARoJ--3g2Ua";
export var svg__ac1YB = "PlasmicValuesSection3-module--svg__ac1YB--24DsB";
export var svg__ywf6M = "PlasmicValuesSection3-module--svg__ywf6M--2ag-O";
export var valueprop__haono = "PlasmicValuesSection3-module--valueprop__haono--3F-gn";
export var svg__beMg = "PlasmicValuesSection3-module--svg__beMg--JV1Rv";
export var svg__ifWzv = "PlasmicValuesSection3-module--svg__ifWzv--1kAbs";
export var valueprop__dke0B = "PlasmicValuesSection3-module--valueprop__dke0B--1CgF5";
export var svg__skMVn = "PlasmicValuesSection3-module--svg__skMVn--neve7";
export var svg___2WdOd = "PlasmicValuesSection3-module--svg___2WdOd--l4upB";